import CreativesPopCodeReact from './CreativesPopCode.react';
import { POP_CODE_CONTENT, POP_CODE_TABS } from '../../../utils/popCodeData.js';
import Skeleton from '../Skeleton/Skeleton.js';
import { getFeatureFlagValueFromLocalStorage } from '../../../utils/featureFlagHelper.js';

const CreativesPopCodeSections = ({
  dispatch,
  currentPopCode,
  affId,
  aff_sub,
  popCodeTrackingLink,
  trackingLink,
  generateCreativeCode,
  offerId,
  popCodeTabActive,
  setPopCodeTabActive,
  handlePopCodeTab,
  creativesLoading,
}) => {
  const setPopCodeTab = ({ tab }) => {
    if (creativesLoading) {
      return;
    }
    setPopCodeTabActive(tab);
    handlePopCodeTab({ tab });
  };

  const activeTitle = POP_CODE_CONTENT[popCodeTabActive].title;
  const activeParagraph = POP_CODE_CONTENT[popCodeTabActive].paragraph;
  const activeIcon = POP_CODE_CONTENT[popCodeTabActive].icon;

  return (
    <>
      <div className="adToolInner row">
        {POP_CODE_TABS.map(({ key, icon, title }, index) => (
          <div
            key={index}
            className={`adToolInnerTab ${popCodeTabActive === key ? 'active' : ''}`}
            onClick={() => setPopCodeTab({ tab: key })}
          >
            {icon}
            <span>{title}</span>
          </div>
        ))}
      </div>

      <div className="popCodeContent">
        {creativesLoading ? (
          <Skeleton height="600px" width="100%" />
        ) : (
          <>
            <div className="popCodeParagraph">
              <div className="iconBox">{activeIcon}</div>
              <div className="contentBox">
                <div className="contentBoxTitle">{activeTitle}</div>
                <div className="contentBoxParagraph">{activeParagraph}</div>
              </div>
            </div>

            {currentPopCode ? (
              <CreativesPopCodeReact
                title={activeTitle}
                creativeCode={
                  currentPopCode.code
                    ? generateCreativeCode({
                        affId,
                        affSub: aff_sub,
                        creativeCode: currentPopCode.code,
                        fileId: currentPopCode.id,
                        offerId,
                        trackingLink: popCodeTrackingLink,
                        uriTrackingLink: encodeURIComponent(trackingLink),
                      })
                    : ''
                }
                dispatch={dispatch}
                isLoading={currentPopCode.isFetching}
                offerFileId={currentPopCode.id}
                popCodeTabActive={popCodeTabActive}
              />
            ) : (
              'Pop code not found'
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CreativesPopCodeSections;
