import PropTypes from 'prop-types';
import InvoiceIcon from './InvoiceIcon';
import { getCurrentPeriod } from '../../utils/dateHelper';
import Tooltip from '../../components/reusables/Tooltip.react';
import ActiveIcon from './ActiveIcon';
import { FormattedNumber } from 'react-intl';
import RoundArrow from './RoundArrow';
import PendingIcon from './PendingIcon';
import { Gtm } from '../../utils/gtm';
import React from 'react';
import moment from 'moment/moment';

const CurrentPeriodTable = ({ currentPeriodPayout, paymentsList, handleInvoiceRequest, paymentTerm, windowWidthSize }) => {
  const startDate = moment(getCurrentPeriod(undefined, paymentTerm).dateStart).format('YYYY/MM/DD');
  const endDate = moment(getCurrentPeriod(undefined, paymentTerm).dateEnd).format('YYYY/MM/DD');
  const carriedOverList = paymentsList.filter((payment) => payment.type !== 'Payment' && payment.status === 'Carried Over');
  const amount = parseFloat(currentPeriodPayout).toFixed(2);
  return (
    <div className="payment-row current-period">
      <div className={`tr payment ${carriedOverList.length === 0 && 'no-border'}`}>
        <div className="td">
          <div className="payment-box">
            <InvoiceIcon />
            <div className="payment-info">
              <span className="payment-date">
                {startDate} - {endDate}
                <span className="current-tag">Current Period</span>
              </span>
              <span className="current-period-specification">(This amount may vary until the end of the period)</span>
            </div>
          </div>
        </div>
        <Tooltip
          closeIcon
          content={<p>You can still increase your payout until the end of the period.</p>}
          position={windowWidthSize >= 576 ? 'right' : 'bottom'}
        >
          <div className="active-status">
            <ActiveIcon /> Active
          </div>
        </Tooltip>
        <div className="td">
          <div className="amount-box">
            <FormattedNumber currency="USD" style="currency" value={amount} />
          </div>
        </div>
      </div>
      <div className="invoices carried-over">
        {carriedOverList.map((carriedOver, index) => {
          const carriedOverStartDate = moment(carriedOver.start_date).format('YYYY/MM/DD');
          const carriedOverEndDate = moment(carriedOver.end_date).format('YYYY/MM/DD');
          const carriedOverAmount = parseFloat(carriedOver.amount).toFixed(2);
          return (
            <div className="tr" key={index}>
              <div className="td">
                <div className="payment-box">
                  <RoundArrow />
                  <InvoiceIcon />
                  {carriedOverStartDate} - {carriedOverEndDate}
                </div>
              </div>
              <div className="td pending-status">
                <PendingIcon /> Pending
              </div>
              <div className="td">
                <div className="amount-box">
                  <FormattedNumber currency={carriedOver.currency} style="currency" value={carriedOverAmount} />
                  <div className="pdf-invoice details-box" onClick={(e) => handleInvoiceRequest(e, carriedOver.id)}>
                    <i
                      className="material-icons primary-color "
                      onClick={() => Gtm.newEvent('payment_history', false, 'click', 'string', 'print icon - invoice')}
                    >
                      remove_red_eye
                    </i>
                    <span className="details-button">details</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

CurrentPeriodTable.propTypes = {
  currentPeriodPayout: PropTypes.number.isRequired,
  handleInvoiceRequest: PropTypes.func.isRequired,
  paymentTerm: PropTypes.string,
  paymentsList: PropTypes.array.isRequired,
  windowWidthSize: PropTypes.number.isRequired,
};

export default CurrentPeriodTable;
