import { browserHistory } from 'react-router';
import { setActionMessage } from '../components/Application/actions/application-actions';
import { logout } from '../components/Login/actions/login-actions';
import { SHOW_TES_AFFILIATE_SEVILLE_TYPE } from './gamificationHelper';

export default class HandleAPIError {
  constructor(res, dispatch) {
    this.res = res;
    this.dispatch = dispatch;
  }

  redirectIfNotLoggedIn() {
    if (!!this.res && (this.res.status === 401 || this.res.status === 403)) {
      this.dispatch(logout());
      if (browserHistory) {
        browserHistory.push('/login');
      }
    }
  }

  showErrorMessage() {
    if (!!this.res && !!this.res.body) {
      if (this.res.status !== 401 && this.res.body.errorMessage) {
        this.dispatch(
          setActionMessage('error', {
            text: this.res.body.errorMessage,
          })
        );
      }
    } else {
      console.error('Failed to reach API');
    }
  }

  handleAll() {
    this.redirectIfNotLoggedIn();
    this.showErrorMessage();
  }

  handleGamificationEventResponse() {
    if (this.res?.body?.length) {
      for (const n of this.res.body) {
        if (n.type === SHOW_TES_AFFILIATE_SEVILLE_TYPE) {
          if (n.error?.type === 'BadgeIsLockedError') {
            this.dispatch(
              setActionMessage('error', {
                text: 'Sorry, badge unlock period is closed.',
              })
            );
          }
        }
      }
    }
  }
}
