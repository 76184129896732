import util from 'util';
import { mockBadgeData } from '../badgeDataConfig';
import { getFeatureFlagValueFromLocalStorage } from './featureFlagHelper';

export const LOGIN_STREAK_TYPE = 'login_streak';
export const PAYOUT_MILESTONE_TYPE = 'payout_milestone';
export const FIRST_PAYMENT_TYPE = 'first_payment';
export const PAYOUT_AMOUNT_TYPE = 'payout_amount';
export const MAX_LOGIN_STREAK_BADGE_LEVEL = 16;
export const SHOW_TES_AFFILIATE_EVENT_TYPE = 'show_tes_affiliate';
export const SHOW_TES_AFFILIATE_SEVILLE_TYPE = 'show_tes_affiliate_seville';
export const LOGIN_EVENT_TYPE = 'login';

export function snakeCaseString(str) {
  if (!str) {
    return '';
  }
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map((s) => s.toLowerCase())
      .join('_')
  );
}

export const getBadgeIconPath = (badge, unlocked = true) => {
  if (!badge) {
    return '';
  } else {
    if (badge.type === 'payout_milestone') {
      return `/img/gamification/badges/icons/${badge.type}_${badge.badgeData.subtype}_${badge.level}${
        unlocked ? '' : '_locked'
      }.png`;
    } else if (badge.type === 'upcoming') {
      return `/img/gamification/badges/icons/${badge.type}.png`;
    } else {
      return `/img/gamification/badges/icons/${badge.type}${
        badge.badgeData?.progressive ? `_${snakeCaseString(badge.badgeData.subtype)}` : ''
      }${badge.year ? `_${badge.year}` : ''}${badge.badgeData?.progressive ? '' : `_${badge.level}`}${
        unlocked ? '' : '_locked'
      }.png`;
    }
  }
};

export const getBadgeIconSrc = (badge) => {
  if (!badge) {
    return '';
  } else {
    if (badge?.isUnlocked) {
      return badge.badgeData?.thumbnailUrl ?? getBadgeIconPath(badge);
    } else {
      return getBadgeIconPath(badge, false);
    }
  }
};

export const getBadgeName = (badge) => {
  if (badge) {
    return badge.title;
  }
  return '';
};

export const getBadgeDescriptionText = (badge) => {
  if (badge) {
    if (badge.type === 'payout_milestone' && badge.isUnlocked) {
      const formattedNumber = badge.badgeData?.value
        ? badge.badgeData?.value.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            notation: 'compact',
            compactDisplay: 'short',
          })
        : '';
      return util.format(badge.badgeData?.textUnlocked, `${formattedNumber}`);
    } else {
      return badge.isUnlocked ? badge.badgeData?.textUnlocked : badge.badgeData?.textLocked;
    }
  }
  return '';
};

export const TrustPilotMailToSubject = (affId) =>
  `mailto:support@crakrevenue.com?subject=Trustpilot%20Review%20Proof&body=Hello%20there%2C%0D%0A%0D%0APlease%20find%20attached%20the%20proof%20of%20my%20review%20done%20on%20Trustpilot%20to%20unlock%20the%20Review%20Rock%20Star%20Badge.%0D%0AMy%20affiliate%20ID%20is%3A%20${affId}%0D%0A%0D%0A(Attach%20proof%20here)`;

export const compareAchievementBadges = (badge1, badge2) => {
  if (badge1?.isUnlocked) {
    if (!badge2?.isUnlocked) {
      return -1;
    }
    return (badge1?.badgeData?.affiliate_badge_count ?? 0) - (badge2?.badgeData?.affiliate_badge_count ?? 0);
  } else {
    if (badge2?.isUnlocked) {
      return 1;
    }
    return -((badge1?.badgeData?.affiliate_badge_count ?? 0) - (badge2?.badgeData?.affiliate_badge_count ?? 0));
  }
};

export const getStreakAchievementBadges = (gamificationBadges, affiliateBadges, currentLoginStreakProgress) => {
  const badgesByTypeMapObject = new Map();
  if (currentLoginStreakProgress) {
    const currentLoginStreakLevel = currentLoginStreakProgress?.progressData.streak;
    const badgeLoginStreakLevel =
      currentLoginStreakLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? MAX_LOGIN_STREAK_BADGE_LEVEL : currentLoginStreakLevel;
    const progressiveLoginStreakBadge = gamificationBadges.find(
      (b) => b.type === LOGIN_STREAK_TYPE && b.level === badgeLoginStreakLevel
    );
    if (progressiveLoginStreakBadge) {
      badgesByTypeMapObject.set(getBadgeName(progressiveLoginStreakBadge), {
        ...progressiveLoginStreakBadge,
        isUnlocked: true,
      });
    }
  }
  for (const badge of gamificationBadges) {
    const unlockedBadge = affiliateBadges.find((ab) => ab?.badge?.id === badge.id);
    if (badge.badgeData?.progressive) {
      if (badge.type === PAYOUT_MILESTONE_TYPE && unlockedBadge) {
        const currentLoginAchievementBadge = badgesByTypeMapObject.get(getBadgeName(badge));
        if (!currentLoginAchievementBadge || (currentLoginAchievementBadge && currentLoginAchievementBadge.level < badge.level)) {
          badgesByTypeMapObject.set(getBadgeName(badge), {
            ...badge,
            isUnlocked: true,
          });
        }
      }
    }
  }
  const loginStreakAugmentedData = mockBadgeData.find((b) => b.type === LOGIN_STREAK_TYPE);
  if (!badgesByTypeMapObject.get(getBadgeName(loginStreakAugmentedData))) {
    badgesByTypeMapObject.set(getBadgeName(loginStreakAugmentedData), {
      level: 0,
      isUnlocked: false,
      ...loginStreakAugmentedData,
      badgeData: {
        ...loginStreakAugmentedData.badgeData,
        subtype: 'bronze',
      },
    });
  }
  const payoutMileStoneAugmentedData = mockBadgeData.find((b) => b.type === PAYOUT_MILESTONE_TYPE);
  if (!badgesByTypeMapObject.get(getBadgeName(payoutMileStoneAugmentedData))) {
    badgesByTypeMapObject.set(getBadgeName(payoutMileStoneAugmentedData), {
      level: 0,
      isUnlocked: false,
      ...payoutMileStoneAugmentedData,
      badgeData: {
        ...payoutMileStoneAugmentedData.badgeData,
        subtype: 'bronze',
      },
    });
  }

  return Array.from(badgesByTypeMapObject.values()).sort(compareAchievementBadges);
};

export const getLoginStreakBadgeFromLevel = (loginStreakBadges, level) => {
  if (loginStreakBadges?.length) {
    return loginStreakBadges.find((b) => b.level === Math.min(level, MAX_LOGIN_STREAK_BADGE_LEVEL));
  }
  return undefined;
};

export const getSpecialAchievementBadges = (gamificationBadges, affiliateBadges, currentLoginStreakProgress) => {
  const currentLoginStreakLevel = currentLoginStreakProgress?.progressData.streak;
  const badgeLoginStreakLevel =
    currentLoginStreakLevel >= MAX_LOGIN_STREAK_BADGE_LEVEL ? MAX_LOGIN_STREAK_BADGE_LEVEL : currentLoginStreakLevel;
  const badgesByTypeMapObject = new Map();
  for (const badge of gamificationBadges) {
    const unlockedBadge = affiliateBadges.find((ab) => ab.badge?.id === badge.id);
    let isNew = false;
    if (!badge.badgeData?.progressive) {
      isNew = true;
    } else {
      if (badge.type === LOGIN_STREAK_TYPE && unlockedBadge && badge.level > badgeLoginStreakLevel) {
        const highestWeekStreak = unlockedBadge?.affiliateProgress?.progressData?.streak;
        const currentLoginAchievementBadge = badgesByTypeMapObject.get(getBadgeName(badge));
        if (!currentLoginAchievementBadge || (currentLoginAchievementBadge && currentLoginAchievementBadge.level < badge.level)) {
          const matchingBadge = getLoginStreakBadgeFromLevel(
            gamificationBadges?.filter((b) => b.type === LOGIN_STREAK_TYPE),
            highestWeekStreak
          );
          if (matchingBadge) {
            const highestLoginBadge = {
              ...matchingBadge,
              isUnlocked: !!unlockedBadge,
              title: 'Login Legend History',
              level: highestWeekStreak,
              badgeData: {
                ...matchingBadge?.badgeData,
                progressive: false,
                textUnlocked:
                  'Congratulations! You’ve unlocked this badge by achieving your highest weekly login streak so far. This highlights your dedication and commitment to growth. Keep the streak alive and aim to beat your personal best!',
              },
            };
            badgesByTypeMapObject.set(getBadgeName(badge), highestLoginBadge);
          }
        }
      }
    }
    if (isNew && getBadgeName(badge) && (!badge?.badgeData?.onlyUnlocked || (badge?.badgeData?.onlyUnlocked && unlockedBadge))) {
      badgesByTypeMapObject.set(getBadgeName(badge), {
        ...badge,
        isUnlocked: !!unlockedBadge,
      });
    }
  }

  return Array.from(badgesByTypeMapObject.values()).sort(compareAchievementBadges);
};
