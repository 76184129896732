import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { reset } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import messages from '../i18n/base-en.js';

import request from '../utils/Req';
import StatisticsReports from './statistics/StatisticsReports.react';
import StatisticsSubReports from './statistics/StatisticsSubReports.react';
import StatisticsFiltersContainer from './statistics/StatisticsFiltersContainer.react';
import StatisticsTableContainer from './statistics/StatisticsTableContainer.react';
import StatisticsGraphContainer from './statistics/StatisticsGraphContainer.react';
import StatisticsDrillDownBreadcrumb from './statistics/StatisticsDrillDownBreadcrumb.react';
import { Container, Info, NewsBox, Pagination, VideoModalIcon } from '../components/reusables';
import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

import { openModal } from '../components/Modal/modal-actions/modal-actions.js';
import { initialState } from '../reducers/statistics-reducers';
import { Gtm } from '../utils/gtm';

// TODO: remove jQuery from project
import $ from 'jquery';
import '../assets/js/vendors/jquery.modal';

import {
  changeColumnSort,
  changeStatisticsListPage,
  changeStatisticsRowsPerPage,
  fetchAllSavedReports,
  fetchAllStatistics,
  fetchGraphStatistics,
  fetchStatistics,
  resetStatistics,
  setDrillDownLevel,
} from '../actions/statistics-actions';
import {
  fetchOffersList,
  fetchAllGoals,
  fetchAllBrowsers,
  fetchOffersCategories,
  fetchAllCountries,
} from '../components/Application/actions/application-actions';

import { buildStatisticsSearch, detectMobileAndTablet } from '../utils/helpers';
import { getLastSevenPeriod } from '../utils/dateHelper.js';

import consts from '../utils/consts';
import config from '../config';

const { dateStart, dateEnd } = getLastSevenPeriod();
const { API_URL } = config;
const { MOBILE_STATS_REPORTS } = consts;

class Statistics extends Component {
  constructor(props) {
    super(props);

    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsChange = this.handleRowsChange.bind(this);
    this.handleSaveReport = this.handleSaveReport.bind(this);
    this.handleSetDrillDownLevel = this.handleSetDrillDownLevel.bind(this);
    this.openAddColumnModal = this.openAddColumnModal.bind(this);
    this.requestReload = this.requestReload.bind(this);
    this.fetchData = this.fetchData.bind(this);

    this.state = {
      hasInfo: false,
      refresh: false,
    };
  }

  UNSAFE_componentWillMount() {
    const { skinConfigurations } = this.props;

    this.skin = new SkinConfigurationsUtils(skinConfigurations);
    this.skin.manageAccess(browserHistory, 'statistics');
  }

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      dispatch,
      statisticsIsFetching,
      statisticsInfos,
      filters,
      statisticsDateStart,
      statisticsDateEnd,
      statisticsColumns,
      statisticsSort,
      statisticsGroups,
      statisticsSettings,
      statisticsTableLevel,
    } = this.props;
    const { refresh } = this.state;

    if (refresh) {
      this.setState({
        refresh: false,
      });
    }

    if (
      (!_.isEqual(filters, nextProps.filters) ||
        nextProps.statisticsDateStart !== statisticsDateStart ||
        nextProps.statisticsDateEnd !== statisticsDateEnd ||
        !_.isEqual(statisticsColumns, nextProps.statisticsColumns) ||
        !_.isEqual(statisticsSort, nextProps.statisticsSort) ||
        !_.isEqual(statisticsGroups, nextProps.statisticsGroups) ||
        !_.isEqual(statisticsSettings, nextProps.statisticsSettings) ||
        statisticsTableLevel !== nextProps.statisticsTableLevel) &&
      !statisticsIsFetching
    ) {
      const startDate = nextProps.statisticsDateStart || statisticsDateStart;
      const endDate = nextProps.statisticsDateEnd || statisticsDateEnd;
      const search = buildStatisticsSearch(
        startDate,
        endDate,
        nextProps.drillDownValues,
        nextProps.statisticsGroups,
        nextProps.filters,
        nextProps.statisticsColumns,
        nextProps.statisticsTableLevel,
        nextProps.statisticsSort,
        nextProps.statisticsSettings,
        nextProps.statisticsColumnsFilters
      );

      this.setState({
        refresh: true,
      });

      dispatch(fetchStatistics(search, nextProps.statisticsTableLevel));
      dispatch(fetchAllStatistics(search));
    }

    if (statisticsInfos !== nextProps.statisticsInfos) {
      this.setState({
        hasInfo: !!nextProps.statisticsInfos && nextProps.statisticsInfos.total > 0,
      });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(resetStatistics());
  }

  fetchData() {
    const {
      dispatch,
      categories,
      countries,
      offersUrlList,
      statisticsQueries,
      statisticsSummary,
      statisticsReceivedAt,
      statisticsColumns,
      drillDownValues,
      statisticsGroups,
      filters,
      statisticsTableLevel,
      statisticsSort,
      statisticsSettings,
      statisticsColumnsFilters,
      statisticsGraphData,
      graphGroups,
      goalsList,
      browsersList,
      savedReportsList,
    } = this.props;
    return new Promise((fulfill) => {
      const promises = [];

      if (countries.length === 0) {
        promises.push(dispatch(fetchAllCountries([])));
      }

      if (categories.length === 0) {
        promises.push(dispatch(fetchOffersCategories([])));
      }

      if (Object.keys(statisticsQueries).length === 1 && statisticsReceivedAt === null) {
        const columns =
          detectMobileAndTablet() && Object.keys(statisticsSummary).length <= 0
            ? MOBILE_STATS_REPORTS.DAILY.columns
            : statisticsColumns;
        const search = buildStatisticsSearch(
          dateStart,
          dateEnd,
          drillDownValues,
          statisticsGroups,
          filters,
          columns,
          statisticsTableLevel,
          statisticsSort,
          statisticsSettings,
          statisticsColumnsFilters
        );

        promises.push(dispatch(fetchStatistics(search, statisticsTableLevel)), dispatch(fetchAllStatistics(search)));
      }

      if (statisticsGraphData.length === 0) {
        promises.push(
          dispatch(
            fetchGraphStatistics({
              date_start: dateStart,
              date_end: dateEnd,
              groups: graphGroups,
              fields: ['Stat.date', 'Stat.payout'],
              page: 1,
              limit: 0,
            })
          )
        );
      }

      if (goalsList.length === 0) {
        promises.push(dispatch(fetchAllGoals()));
      }

      if (browsersList.length === 0) {
        promises.push(dispatch(fetchAllBrowsers()));
      }

      if (savedReportsList && savedReportsList.length === 0) {
        promises.push(dispatch(fetchAllSavedReports()));
      }

      Promise.all(promises)
        .then(() => fulfill())
        .catch(() => fulfill());
    });
  }

  getStatisticsItemsVisibility() {
    return {
      newsBox: this.skin.isItemVisible('Global', 'NewsBox'),
      moreInfo: this.skin.isItemVisible('Statistics', 'MoreInfo'),
      videoModalIcon: this.skin.isItemVisible('Statistics', 'VideoModalIcon'),
    };
  }

  requestReload() {
    const {
      dispatch,
      filters,
      statisticsColumns,
      statisticsColumnsFilters,
      statisticsSort,
      statisticsGroups,
      statisticsSettings,
      statisticsDateStart,
      statisticsDateEnd,
      statisticsTableLevel,
      drillDownValues,
    } = this.props;
    const search = buildStatisticsSearch(
      statisticsDateStart,
      statisticsDateEnd,
      drillDownValues,
      statisticsGroups,
      filters,
      statisticsColumns,
      statisticsTableLevel,
      statisticsSort,
      statisticsSettings,
      statisticsColumnsFilters
    );

    this.setState({
      refresh: true,
    });

    dispatch(fetchStatistics(search, statisticsTableLevel));
    dispatch(fetchAllStatistics(search));
  }

  handleColumnSort(e) {
    const { dispatch, statisticsSort } = this.props;
    const el = e.target;
    const tagName = el.tagName;
    let field;
    let way = 'desc';

    if (tagName !== 'TH' && el.parentNode.tagName === 'TH') {
      field = el.parentNode.getAttribute('data-field');
    } else {
      field = el.getAttribute('data-field');
    }

    if (Object.prototype.hasOwnProperty.call(statisticsSort, field)) {
      way = statisticsSort[field] === 'desc' ? 'asc' : 'desc';
    }

    dispatch(changeColumnSort(field, way));
  }

  handlePageChange(page) {
    const { dispatch } = this.props;

    dispatch(changeStatisticsListPage(page));
  }

  handleRowsChange(value, page) {
    const { dispatch } = this.props;

    dispatch(changeStatisticsRowsPerPage(value, page));
  }

  openAddColumnModal(e) {
    const { dispatch } = this.props;
    e.preventDefault();

    dispatch(reset('statisticsAddColumn'));

    $('#addColumn').openModal();
  }

  handleSetDrillDownLevel(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    const el = e.target;
    let level = parseInt(el.getAttribute('data-drill'), 10);

    if (level === 0) {
      level = 1;
    }

    dispatch(setDrillDownLevel(level));
  }

  handleSaveReport(e) {
    e.preventDefault();
    const { hasInfo } = this.state;
    const {
      dispatch,
      drillDownValues,
      filters,
      statisticsColumns,
      statisticsColumnsFilters,
      statisticsDateEnd,
      statisticsDateStart,
      statisticsGroups,
      statisticsSettings,
      statisticsSort,
      statisticsTableLevel,
    } = this.props;
    Gtm.event('statistics', 'Click', `save report`);

    if (hasInfo) {
      dispatch(
        openModal({
          name: 'StatisticsSaveReportModal',
          modalProps: {
            dispatch: dispatch,
            report: buildStatisticsSearch(
              statisticsDateStart,
              statisticsDateEnd,
              drillDownValues,
              statisticsGroups,
              filters,
              statisticsColumns,
              statisticsTableLevel,
              statisticsSort,
              statisticsSettings,
              statisticsColumnsFilters
            ),
          },
        })
      );
    }
  }

  render() {
    const {
      browsersList,
      categories,
      countries,
      dispatch,
      drillDownValues,
      filters,
      goalsList,
      graphGroups,
      intl,
      news,
      offersIsFetching,
      offersList,
      offersUrlList,
      reportMainMenu,
      reportSubMenu,
      paymentTerm,
      savedReportsList,
      statisticsColumns,
      statisticsColumnsFilters,
      statisticsDateEnd,
      statisticsDateStart,
      statisticsDrillBreadcrumb,
      statisticsGraphData,
      statisticsGraphIsFetching,
      statisticsGroups,
      statisticsInfos,
      statisticsIsFetching,
      statisticsQueries,
      statisticsSelectedFilters,
      statisticsSelectedRows,
      statisticsSelectedRowsValues,
      statisticsSettings,
      statisticsSort,
      statisticsSummary,
      statisticsTableLevel,
    } = this.props;
    const { hasInfo, refresh } = this.state;
    const search = buildStatisticsSearch(
      statisticsDateStart,
      statisticsDateEnd,
      drillDownValues,
      statisticsGroups,
      filters,
      statisticsColumns,
      statisticsTableLevel,
      statisticsSort,
      statisticsSettings,
      statisticsColumnsFilters
    );

    const exportCsvRequest = request
      .get(`${API_URL}/statistics/export`)
      .query({ search: JSON.stringify({ ...search, page: 1, limit: 0 }) });

    const classes = statisticsIsFetching ? 'is-fetching' : '';

    const resetable =
      !_.isEqual(filters, initialState.filters) ||
      !_.isEqual(statisticsColumns, initialState.columns) ||
      !_.isEqual(statisticsDateEnd, dateEnd) ||
      !_.isEqual(statisticsDateStart, dateStart) ||
      !_.isEqual(statisticsGroups, initialState.groups) ||
      !_.isEqual(statisticsSettings, initialState.statisticsSettings) ||
      !_.isEqual(statisticsSort, initialState.sort) ||
      !_.isEqual(statisticsTableLevel, initialState.queriesLevel);

    const videoUrlObjects = [
      {
        label: intl.formatMessage(messages.statisticsModalTabStatisticsOverview),
        url: 'https://player.vimeo.com/video/392011651',
      },
      {
        label: intl.formatMessage(messages.statisticsModalTabExtraEarnings),
        url: 'https://player.vimeo.com/video/391534086',
      },
    ];

    const IS_VISIBLE = this.getStatisticsItemsVisibility();

    return (
      <div className="container-fluid app-container" id="statistics-page">
        {news.active && IS_VISIBLE.newsBox ? (
          <div>
            <Container className="col-xs-12 col-md-12 col-lg-12 shorter-xlg-col-12 z-depth-2 no-margin-top" id="newsbox">
              <NewsBox content={news} />
            </Container>
          </div>
        ) : null}
        <h1 className="primary-color">
          <FormattedMessage {...messages.statisticsTitle} />
          {IS_VISIBLE.moreInfo ? (
            <Info
              onClick={() => {
                Gtm.event('statistics', 'Click', 'statistics info');
              }}
              title={intl.formatMessage(messages.statisticsMoreInfosStatistics)}
              url="https://support.crakrevenue.com/knowledge-base/category/statistics-crakrevenue/"
            />
          ) : null}
          {IS_VISIBLE.videoModalIcon ? (
            <VideoModalIcon
              className="pointer statsVideoModal"
              dispatch={dispatch}
              gtmEvent={() => {
                Gtm.event('statistics', 'Click', 'video icon');
              }}
              videoUrl={videoUrlObjects}
            />
          ) : null}
        </h1>

        <StatisticsReports
          dispatch={dispatch}
          reportMainMenu={reportMainMenu}
          reportSubMenu={reportSubMenu}
          resetable={resetable}
          savedReportsList={savedReportsList}
          statisticsDateEnd={statisticsDateEnd}
          statisticsDateStart={statisticsDateStart}
          statisticsIsFetching={statisticsIsFetching}
        />

        <div className="statistics-container-div">
          <div className="row">
            <Container
              className="white-card col-md-12 statistics-filters-container z-depth-2 black-title"
              collapsable
              isOpen
              loading={statisticsIsFetching || offersIsFetching}
              title={<FormattedMessage {...messages.genericTextFilters} />}
            >
              <StatisticsFiltersContainer
                browsersList={browsersList}
                categories={categories}
                countries={countries}
                dispatch={dispatch}
                filters={filters}
                goalsList={goalsList}
                offersIsFetching={offersIsFetching}
                offersList={offersList}
                offersUrlList={offersUrlList}
                paymentTerm={paymentTerm}
                queries={statisticsQueries}
                requestReload={this.requestReload}
                statisticsDateEnd={statisticsDateEnd}
                statisticsDateStart={statisticsDateStart}
                statisticsSelectedFilters={statisticsSelectedFilters}
              />
            </Container>
          </div>
        </div>

        <div className="statistics-container-div">
          <div className="row">
            <Container
              className="white-card col-md-12 statistics-filters-container z-depth-2 visible-md visible-lg visible-xlg black-title"
              collapsable
              isOpen
              loading={statisticsGraphIsFetching}
              title={<FormattedMessage {...messages.statisticsGraph} />}
            >
              <StatisticsGraphContainer
                dispatch={dispatch}
                filters={filters}
                graphGroups={graphGroups}
                refresh={refresh}
                searchFilters={search.filters}
                selectedRowsValues={statisticsSelectedRowsValues}
                statisticsDateEnd={statisticsDateEnd}
                statisticsDateStart={statisticsDateStart}
                statisticsGraphData={statisticsGraphData}
              />
            </Container>
          </div>
        </div>

        <div className="statistics-table-section">
          <Container className="statistics-reports-container white-card col-md-12 z-depth-2" loading={statisticsIsFetching}>
            <StatisticsSubReports
              dispatch={dispatch}
              drillDownValues={statisticsDrillBreadcrumb}
              id="addColumn"
              openAddColumnModal={this.openAddColumnModal}
              reportSubMenu={reportSubMenu}
              statisticsDateEnd={statisticsDateEnd}
              statisticsDateStart={statisticsDateStart}
              statisticsIsFetching={statisticsIsFetching}
              statisticsSelectedRows={statisticsSelectedRows}
            />
            <div className={'z-depth-2 round-border ' + classes}>
              <div className="pannelHeader">
                <div className="row">
                  <div className="col-md-9">
                    <StatisticsDrillDownBreadcrumb
                      drillDownValues={statisticsDrillBreadcrumb}
                      handleSetDrillDownLevel={this.handleSetDrillDownLevel}
                      statisticsDateEnd={statisticsDateEnd}
                      statisticsDateStart={statisticsDateStart}
                    />
                  </div>
                  <div className="col-md-3 stat-export">
                    {hasInfo ? (
                      <a
                        className="link"
                        href={`${exportCsvRequest.url}?${exportCsvRequest._query}`}
                        onClick={() => {
                          Gtm.event('statistics', 'Click', 'export csv');
                        }}
                      >
                        <i className="material-icons primary-color">&#xE2C4;</i>
                        <FormattedMessage {...messages.statisticsExportCSV} />
                      </a>
                    ) : (
                      <div className="link disabled">
                        <i className="material-icons primary-color">&#xE2C4;</i>
                        <FormattedMessage {...messages.statisticsExportCSV} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <StatisticsTableContainer
                dispatch={dispatch}
                drillDownValues={drillDownValues}
                handleColumnSort={this.handleColumnSort}
                statisticsColumns={statisticsColumns}
                statisticsQueries={statisticsQueries}
                statisticsSelectedRows={statisticsSelectedRows}
                statisticsSort={statisticsSort}
                statisticsSummary={statisticsSummary}
                statisticsTableLevel={statisticsTableLevel}
              />
              <Pagination
                choices={[25, 50, 100, 200]}
                currentPage={statisticsInfos.currentPage}
                handlePageChange={this.handlePageChange}
                handleRowsChange={this.handleRowsChange}
                pageCount={statisticsInfos.pageCount}
                rowsPerPage={parseInt(statisticsSettings.limit, 10)}
                selectId="rowsPerPageBottom"
                totalResults={statisticsInfos.total}
              />
            </div>
          </Container>
        </div>

        <div className="pull-right save-stat">
          <div
            className={['link', hasInfo ? undefined : 'disabled'].join(' ')}
            onClick={(e) => {
              this.handleSaveReport(e);
            }}
          >
            <i className="material-icons primary-color">save</i>
            <FormattedMessage {...messages.statisticsSaveReport} />
          </div>
        </div>
      </div>
    );
  }
}

Statistics.propTypes = {
  browsersList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  drillDownValues: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  goalsList: PropTypes.array.isRequired,
  graphGroups: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  news: PropTypes.object.isRequired,
  offersIsFetching: PropTypes.bool.isRequired,
  offersList: PropTypes.object.isRequired,
  offersUrlList: PropTypes.array.isRequired,
  paymentTerm: PropTypes.string,
  reportMainMenu: PropTypes.string.isRequired,
  reportSubMenu: PropTypes.string,
  savedReportsList: PropTypes.array.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  statisticsColumns: PropTypes.array.isRequired,
  statisticsColumnsFilters: PropTypes.array.isRequired,
  statisticsDateEnd: PropTypes.any,
  statisticsDateStart: PropTypes.any,
  statisticsDrillBreadcrumb: PropTypes.object.isRequired,
  statisticsGraphData: PropTypes.object,
  statisticsGraphIsFetching: PropTypes.bool.isRequired,
  statisticsGroups: PropTypes.array.isRequired,
  statisticsInfos: PropTypes.object.isRequired,
  statisticsIsFetching: PropTypes.bool.isRequired,
  statisticsQueries: PropTypes.object.isRequired,
  statisticsSelectedFilters: PropTypes.object.isRequired,
  statisticsSelectedRows: PropTypes.array.isRequired,
  statisticsSelectedRowsValues: PropTypes.object.isRequired,
  statisticsSettings: PropTypes.object.isRequired,
  statisticsSort: PropTypes.object.isRequired,
  statisticsSummary: PropTypes.object.isRequired,
  statisticsTableLevel: PropTypes.number.isRequired,
};

export default injectIntl(
  connect((state) => ({
    browsersList: state.application.lists.browsers,
    categories: state.application.lists.offersCategories,
    countries: state.application.lists.countries,
    drillDownValues: state.statistics.drillDownValues,
    filters: state.statistics.filters,
    goalsList: state.application.lists.goals,
    graphGroups: state.statistics.graphGroups,
    news: state.news.data,
    offersIsFetching: state.application.lists.offersIsFetching,
    offersList: state.application.lists.offers,
    offersUrlList: state.application.lists.offersUrl,
    reportMainMenu: state.statistics.reportMainMenu,
    reportSubMenu: state.statistics.reportSubMenu,
    paymentTerm: state.profile.data.customProfile.affiliate.payment_term || '',
    savedReportsList: state.statistics.savedReportsList,
    skinConfigurations: state.skinConfigurations.data,
    statisticsColumns: state.statistics.columns,
    statisticsColumnsFilters: state.statistics.columnsFilters,
    statisticsDateStart: state.statistics.date_start || dateStart,
    statisticsDateEnd: state.statistics.date_end || dateEnd,
    statisticsDrillBreadcrumb: state.statistics.statisticsDrillBreadcrumb,
    statisticsGraphData: state.statistics.graphData,
    statisticsGraphIsFetching: state.statistics.statisticsGraphIsFetching,
    statisticsGroups: state.statistics.groups,
    statisticsInfos: state.statistics.statisticsInfos,
    statisticsIsFetching: state.statistics.statisticsIsFetching,
    statisticsQueries: state.statistics.queries,
    statisticsSelectedFilters: state.statistics.selectedFilters,
    statisticsSelectedRows: state.statistics.selectedRows,
    statisticsSelectedRowsValues: state.statistics.selectedRowsValues,
    statisticsSettings: state.statistics.statisticsSettings,
    statisticsSort: state.statistics.sort || {},
    statisticsSummary: state.statistics.statisticsSummary || {},
    statisticsTableLevel: state.statistics.queriesLevel,
    statisticsReceivedAt: state.statistics.statisticsReceivedAt,
  }))(Statistics)
);
