import PropTypes from 'prop-types';

import ProfileUserBlock from './ProfileUserBlock.react';
import { PaymentHistoryWidgetContainer } from '../../components/reusables';
import { getFeatureFlagValueFromLocalStorage } from '../../utils/featureFlagHelper';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import ProfileAchievementsInfoBox from './ProfileAchievementsInfoBox';
import ProfileRankingPositionBox from './ProfileRankingPositionBox';

const ProfileHeader = ({
  userInfos,
  company,
  payableTo,
  statusRankName,
  skinConfigurations,
  affRank,
  gamificationBadges,
  affiliateBadges,
  gamificationAffProgress,
}) => {
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  useEffect(() => {
    function handleResize() {
      setWindowWidthSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="container-fluid new-profile-header" id="profile-header">
      <div className="app-container header-container">
        <div className="profile-section">
          <ProfileUserBlock
            company={company}
            payableTo={payableTo}
            skinConfigurations={skinConfigurations}
            statusRankName={statusRankName}
            userInfos={userInfos}
          />
        </div>
        <div className={`achievements-box-container ${affRank.current_aff_rank > 0 || affRank.hidden_rank ? '' : 'no-rank'}`}>
          <div className="achievements-box">
            <ProfileRankingPositionBox affRank={affRank} />
            <ProfileAchievementsInfoBox
              affiliateBadges={affiliateBadges}
              gamificationAffProgress={gamificationAffProgress}
              gamificationBadges={gamificationBadges}
              windowWidthSize={windowWidthSize}
            />
            {windowWidthSize >= 1100 && (
              <Link className="achievements-cta" onlyActiveOnIndex to="/achievements">
                See Achievements
              </Link>
            )}
          </div>
          {windowWidthSize <= 1100 && (
            <Link className="achievements-cta" onlyActiveOnIndex to="/achievements">
              See Achievements
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

ProfileHeader.propTypes = {
  company: PropTypes.string.isRequired,
  payableTo: PropTypes.any.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  userInfos: PropTypes.object.isRequired,
};

export default connect((state) => ({
  affStatus: state.profile.data.affStatus,
  affRank: state.profile.data.affRank,
  gamificationEventsStats: state.gamification.eventsData?.notification?.stats,
  gamificationAffProgress: state.gamification.eventsData?.notification?.affiliateProgress,
  gamificationBadges: state.gamification.gamificationBadges,
  badgeDataIsFetching: state.gamification.badgeDataIsFetching,
  eventsDataIsFetching: state.gamification.eventsDataIsFetching,
  affiliateBadgesIsFetching: state.gamification.affiliateBadgesIsFetching,
  loginStreakBadges: state.gamification.loginStreakBadges,
  affiliateBadges: state.gamification.affiliateBadges,
}))(ProfileHeader);
