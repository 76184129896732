import React, { useMemo } from 'react';
import './styles/OfferListingRow.scss';
import { CountryList, Payout, PayoutType } from '../reusables';
import { FormattedNumber } from 'react-intl';
import { OFFER_TABLE_HEADERS, OFFER_TABLE_HEADER_KEYS } from './data';
import OfferListingDetails from './OfferListingDetails.react';
import OfferListingApprovalStatus from './OfferListingApprovalStatus.react';
import OfferListingPreviewChip from './OfferListingPreviewChip.react';
import { Link } from 'react-router';
import EpcTooltipIcon from '../Offers/EpcTooltipIcon';
import { getFeatureFlagValueFromLocalStorage } from '../../utils/featureFlagHelper';

const OfferListingRow = ({ offer }) => {
  const id = offer.id;
  const href = `/offers/${id}`;

  const offerColumns = useMemo(() => {
    const {
      id,
      thumbnail,
      name,
      verticals,
      tags,
      conversion_types,
      default_payout,
      payout_type,
      percent_payout,
      epc_affiliation,
      top_countries,
      targeted_countries,
      approval_status,
      preview_url,
    } = offer;

    const conversionTypes = conversion_types.map((conversionType) => conversionType.name);

    const ComponentNA = <span>N/A</span>;
    const ComponentOffer = (
      <OfferListingDetails id={id} name={name} tags={tags} thumbnail={thumbnail?.thumbnail} verticals={verticals} />
    );
    const ComponentPayout = (
      <Payout
        conversionTypes={conversionTypes}
        defaultPayout={default_payout}
        emptyPayoutText="Varies"
        payoutType={payout_type}
        percentPayout={percent_payout}
      />
    );
    const ComponentEPC = epc_affiliation ? (
      <FormattedNumber currency="USD" minimumFractionDigits={4} style="currency" value={parseFloat(epc_affiliation || 0)} />
    ) : (
      ComponentNA
    );
    const ComponentPayoutType = <PayoutType categories={conversionTypes} />;
    const ComponentCountries = top_countries ? <CountryList list={top_countries} /> : ComponentNA;
    const ComponentTargetedCountries = targeted_countries ? <CountryList list={targeted_countries} /> : ComponentNA;
    const ComponentStatus = <OfferListingApprovalStatus approval_status={approval_status} />;
    const ComponentPreview = <OfferListingPreviewChip preview_url={preview_url} />;

    const componentColumns = {
      [OFFER_TABLE_HEADER_KEYS.offer]: ComponentOffer,
      [OFFER_TABLE_HEADER_KEYS.payout]: ComponentPayout,
      [OFFER_TABLE_HEADER_KEYS.epc]: ComponentEPC,
      [OFFER_TABLE_HEADER_KEYS.payoutType]: ComponentPayoutType,
      [OFFER_TABLE_HEADER_KEYS.countries]: ComponentCountries,
      [OFFER_TABLE_HEADER_KEYS.status]: ComponentStatus,
      [OFFER_TABLE_HEADER_KEYS.preview]: ComponentPreview,
    };

    if (getFeatureFlagValueFromLocalStorage('targeted_countries') === '1') {
      componentColumns[OFFER_TABLE_HEADER_KEYS.targetedCountries] = ComponentTargetedCountries;
    }

    const columns = OFFER_TABLE_HEADERS.map(({ keyHeader, title, mobileTitle }) => {
      const column = {
        keyHeader,
        component: componentColumns[keyHeader] || <></>,
        mobileTitle: mobileTitle ? title : '',
      };

      return column;
    });

    return columns;
  }, [offer]);

  return (
    <Link className="offerListingRow" to={href}>
      {offerColumns.map(({ keyHeader, component, mobileTitle }) => (
        <div className={`offerListingGridColumn column-${keyHeader}`} key={keyHeader}>
          {mobileTitle ? (
            <span className="offerListingGridColumnMobileTitle">
              {mobileTitle}
              {keyHeader === 'epc' && <EpcTooltipIcon closeIcon id={`tooltip-epc-${id}`} position="bottom" />}
            </span>
          ) : null}
          {component}
        </div>
      ))}
    </Link>
  );
};

export default OfferListingRow;
