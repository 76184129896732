import React, { createContext, useState, useCallback, useEffect, useContext } from 'react';
import { PREVIEW_TABS, adToolDefaultState, adToolText, formatCreativeCode } from './adTools/values';
import Intro from './adTools/Intro.js';
import CodeInput from './adTools/CodeInput';
import Config from './adTools/Config';
import { fetchPostitialTrackingLink } from '../../../pages/offer/Offer-Actions/Offer-Actions';
import PreviewTabs from './adTools/PreviewTabs';
import { setActionMessage } from '../../Application/actions/application-actions';
import messages from '../../../i18n/base-en.js';
import { Gtm } from '../../../utils/gtm';
import { connect } from 'react-redux';
import Iframe from './adTools/Iframe';
import { injectIntl } from 'react-intl';

const AdToolsContext = createContext(undefined);

const CreativeAdTools = React.memo(
  ({
    isReferrals,
    isAutoOptimized,
    skinConfigurations,
    adToolType,
    offerId,
    strippedValues,
    creativeCode,
    dispatch,
    landingPagesIds,
    offerLandingPages,
    intl,
    fields,
    creativeLink,
    isLiveCamWidget,
    isVast,
    show = true,
  }) => {
    const isChathead = adToolType === 'chathead' || isLiveCamWidget;
    const text = isVast ? '' : adToolText[adToolType];

    const defaultState = adToolDefaultState[adToolType];

    const getActiveLandingPages = (landingPages) => {
      if (landingPages) {
        return landingPages.filter((landingPage) => landingPage.status === 'active');
      }
      return [];
    };

    const gtmEvent = (name) => Gtm.event(adToolType, 'Click', name);

    let landingPages = landingPagesIds?.map((id) => offerLandingPages?.find(({ id: landingPageId }) => landingPageId === id));

    landingPages = landingPages?.filter(
      (landing) => (landing?.id && landing?.name && landing?.preview_url) || (isAutoOptimized && landing?.id === 'optimized')
    );

    const activeLandingPages = getActiveLandingPages(landingPages);

    const [offerState, setOfferState] = useState({
      loading: activeLandingPages?.length,
      selectedOffer:
        adToolType === 'postitial'
          ? activeLandingPages.find((landing) => landing.id === fields?.url_id?.value)
          : activeLandingPages?.[0],
      dataAvailable: activeLandingPages?.length,
    });

    const [postitialState, setPostitialState] = useState(defaultState);

    const [previewTab, setPreviewTab] = useState(PREVIEW_TABS.SHOW_PREVIEW);

    const dataAvailable = offerState.dataAvailable;
    const selectedOffer = offerState.selectedOffer;

    useEffect(async () => {
      const noDataAvailable = () => {
        setOfferState((state) => ({
          ...state,
          loading: false,
          dataAvailable: false,
        }));
      };
      if (selectedOffer) {
        let url = '';
        try {
          url = await fetchPostitialTrackingLink(offerId, { ...strippedValues, url_id: `${selectedOffer.id}` });
        } catch (e) {
          console.error(e);
        }
        if (!url) {
          noDataAvailable();
          return;
        }
        url = new URL(url);
        const params = new URLSearchParams(url.search);
        //only apply aff_sub if the affiliate did not set it in their options
        if (!params.get('aff_sub4')) {
          url.searchParams.set('aff_sub4', adToolType === 'postitial' ? 'AT_0016' : 'AT_0017');
        }
        const contentUrl = selectedOffer.id === 'optimized' ? url : new URL(selectedOffer.preview_url);
        contentUrl.searchParams.set('aff_id', '1');
        contentUrl.searchParams.set('transaction_id', 'postitial');
        setPostitialState((state) => ({
          ...state,
          url: creativeLink || url.href,
          contentUrl: contentUrl.href,
        }));
        setOfferState((state) => ({
          ...state,
          loading: false,
        }));
      } else {
        noDataAvailable();
      }
    }, [offerId, selectedOffer, strippedValues, adToolType, creativeLink]);

    if (creativeCode && !isLiveCamWidget) {
      creativeCode = formatCreativeCode({
        object: postitialState,
        creativeCode,
      });
    }

    const copy = useCallback(() => {
      navigator.clipboard.writeText(creativeCode);

      const text = creativeCode.length > 170 ? `${creativeCode.substr(0, 170)}...` : creativeCode;

      dispatch(
        setActionMessage(
          'success',
          {
            text: intl.formatMessage(messages.genericTextTextCopied, { text }),
          },
          3000
        )
      );
      gtmEvent(`copy ${adToolType}`);
    }, [creativeCode]);

    return (
      <AdToolsContext.Provider
        value={{
          isChathead,
          isLiveCamWidget,
          isVast,
          isReferrals,
          isAutoOptimized,
          skinConfigurations,
          adToolType,
          gtmEvent,
          text,
          dataAvailable,
          offerState,
          setOfferState,
          creativeCode,
          previewTab,
          setPreviewTab,
          landingPages: activeLandingPages,
          postitialState,
          setPostitialState,
          copy,
          fields,
        }}
      >
        <div id="creativesAdTools">
          {!isVast && <Intro />}
          <div className="innerRow">
            <div className={`previewBox ${isChathead ? 'chatHead' : ''}`}>
              {offerState.loading && !isLiveCamWidget && !isVast ? (
                <div className="loadingBox">{text.loading}</div>
              ) : !dataAvailable && !isChathead && !isVast ? (
                <div className="loadingBox textarea-css">{text.noData}</div>
              ) : (
                <>
                  {!isLiveCamWidget && !isVast && <CodeInput />}
                  <Iframe show={show} />
                  {!isLiveCamWidget && !isVast && <PreviewTabs />}
                </>
              )}
            </div>
            {!isChathead && !isVast && <Config />}
            {(offerState.loading || !dataAvailable) && !isChathead && !isVast ? <div className="loadingCover" /> : null}
          </div>
        </div>
      </AdToolsContext.Provider>
    );
  }
);

export const useAdToolsContext = () => useContext(AdToolsContext);

export default connect((state) => ({
  creativeLink: state.offer.creativeLink,
}))(injectIntl(CreativeAdTools));
